<template>
<loader v-bind="{ loading }">
  <form>
    <columns>
      <column>
        <data-selector
          classes="is-medium is-rounded"
          required
          searchable
          disabled
          :items="customer_list"
          :value="workOrder.customer_uuid"
          @input="updateCustomer"
          :error="$root.errors.customer_uuid"
          value-key="uuid"
          label-key="name">
          Customer
        </data-selector>
      </column>
      <column>
        <data-selector
          classes="is-medium is-rounded"
          :error="$root.errors.location_uuid"
          :items="customerLocations"
          :value="workOrder.location_uuid"
          @input="updateLocation"
          value-key="uuid"
          label-key="name"
          required>
          Location
        </data-selector>
      </column>
      <column>
        <data-selector
          classes="is-medium is-rounded"
          :error="$root.errors.team_uuid"
          :items="availableTeams"
          :value="workOrder.team_uuid"
          @input="updateTeam"
          value-key="uuid"
          label-key="name"
          required>
          Team
        </data-selector>
      </column>
      <column class="is-narrow">
        <switch-input
          classes="is-medium is-rounded"
          description="Customer contacts will not receive notifications"
          :value="workOrder.internal"
          @input="toggleInternal"
          :error="$root.errors.internal"
          required>
          Internal Only
        </switch-input>
      </column>
    </columns>
    <columns>
      <column class="is-6">
        <text-input
          classes="is-medium is-rounded"
          required
          :error="$root.errors.reference"
          :value="workOrder.reference"
          @input="updateReference">
          Reference
        </text-input>
      </column>
      <column>
        <data-selector
          classes="is-medium is-rounded"
          :error="$root.errors.classification_uuid"
          :items="work_order_classifications"
          :value="workOrder.classification_uuid"
          @input="updateClassification"
          value-key="uuid"
          label-key="title"
          required>
          Classification
        </data-selector>
      </column>
      <column>
        <data-selector
          classes="is-medium is-rounded"
          :error="$root.errors.priority_id"
          :items="priorities"
          :value="workOrder.priority_id"
          @input="updatePriority"
          value-key="id"
          label-key="level"
          required>
          Priority
        </data-selector>
      </column>
    </columns>
    <columns>
      <column>
        <h4 class="title is-4">Scheduling</h4>
        <columns>
          <column class="is-6">
            <date-picker
              classes="is-medium is-rounded"
              :error="$root.errors.starting_at"
              clearable
              required
              :value="workOrder.starting_at"
              @input="updateStartingDate">
              Starting Date/Time
            </date-picker>
          </column>
          <column>
            <date-picker
              classes="is-medium is-rounded"
              :error="$root.errors.ending_at"
              clearable
              :value="workOrder.ending_at"
              @input="updateEndDate">
              Anticipated End Date/Time
            </date-picker>
          </column>
        </columns>
      </column>
    </columns>
    <columns>
      <column>
        <text-area
          classes="is-rounded"
          :value="workOrder.brief"
          @input="updateBrief"
          placeholder="Any information that the team may require to complete the work ahead.">
          Briefing
        </text-area>
      </column>
    </columns>
    <columns>
      <column>
        <submit-button
          class="is-medium is-rounded"
          :working="saving"
          @submit="save">
          Save
        </submit-button>
      </column>
      <column class="is-narrow">
        <div class="buttons has-addons is-rounded">
          <!-- <action-button
            class="is-small is-rounded"
            :working="closing"
            @click="markAsComplete">
            Mark as Complete
          </action-button> -->
          <action-button
            class="is-small is-danger is-rounded"
            :working="deleting"
            @click="deleteWorkOrder">
            Delete
          </action-button>
        </div>
      </column>
    </columns>
  </form>
</loader>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { find, flatMap } from 'lodash'
import { priority } from '@/api'

export default {

  data: () => ({
    loading: true,
    saving: false,
    deleting: false,
    closing: false,
    priorities: []
  }),

  async created() {
    await priority.loadPriorityList(({data}) => this.priorities = data)
    await this.loadCustomerList()
    await this.loadTeamList()
    await this.loadWorkOrderClassificationList()
    this.loading = false
  },

  methods: {
    ...mapActions('customer', ['loadCustomerList']),
    ...mapActions('team', ['loadTeamList']),
    ...mapActions('workOrderClassification', ['loadWorkOrderClassificationList']),
    ...mapMutations('workOrder', [
      'updateCustomer',
      'updateLocation',
      'updateReference',
      'updatePriority',
      'updateTeam',
      'updateClassification',
      'updateStartingDate',
      'updateEndDate',
      'updateBrief',
      'toggleInternal'
    ]),
    save() {
      this.saving = true
      this.$store.dispatch('workOrder/save').then(() => {
        this.saving = false
        this.$toast.success('Saved')
      }, () => {
        this.saving = false
      })
    },
    async deleteWorkOrder() {
      if(
        await this.$confirm({
          'title': 'Delete Work Order',
          'message': 'Are you sure you want to delete this Work Order?'
        })) {
          this.deleting = true
          this.$store.dispatch('workOrder/delete').then(() => {
            this.deleting = false
            this.$toast.success('Deleted')
            this.$router.push({
              name: 'work-orders'
            })
          }, () => {
            this.deleting = false
          })
        }
    },
    async markAsComplete() {
      if(
        await this.$confirm({
          'title': 'Close Work Order',
          'message': 'Are you sure you want to mark this Work Order as being complete?'
        })) {
          this.closing = true
          this.$store.dispatch('workOrder/markAsComplete').then(() => {
            this.closing = false
            this.$toast.success('Marked as Complete')
            this.$router.push({
              name: 'work-orders'
            })
          }, () => {
            this.closing = false
          })
        }
    }
  },

  computed: {
    ...mapGetters('workOrder', ['workOrder']),
    ...mapGetters('customer', ['customer_list']),
    ...mapGetters('team', ['team_list']),
    ...mapGetters('workOrderClassification', ['work_order_classifications']),
    activeCustomer() {
      return find(this.customer_list, customer => customer.id === this.workOrder.customer_id)
    },
    customerLocations() {
      return this.activeCustomer ? this.activeCustomer.locations : []
    },
    availableTeams() {
      return this.activeCustomer ? this.team_list.filter(team => {
        const branch_ids = flatMap(this.activeCustomer.locations.map(location => {
          return location.branches.map(branch => branch.id)
        }))
        return branch_ids.includes(team.branch_id)
      }) : []
    }
  }

}
</script>